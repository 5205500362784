//import "./index.js"
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
function ClientSignin() {

  const { search } = useLocation();
  const redirect_url = new URLSearchParams(search).get("redirect_url");

  const [redirection_url, setRedirectUrl] = useState("");

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState([])
  const [emailValidationErrors, setEmailValidationErrors] = useState("")
  const [passwordValidationErrors, setPasswordValidationErrors] = useState("")




  let checkLogout = async () => {
    try {

      let code = window.localStorage.getItem('authfast')

      if (code) {
        let res = await fetch("https://authfast-production.up.railway.app/v1.0/auth/signout/client", {
          method: "POST",
          headers: new Headers({
            "Content-Type": "application/json",
            "Accepts": "application/json"
          }),
          body: JSON.stringify({
            code
          }),
        });
        window.localStorage.removeItem('authfast')
      }

      window.location.href = redirect_url + '?logout=true'

    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    checkLogout()
  }, [
  ]);




  return (
    <div className="bg-blue-400 h-screen grid my-auto">
      <div className="m-auto w-1/3">
        <h1 className="text-4xl pt-5 mb-5 ">Logout In</h1>
      </div>
    </div>
  );
}

export default ClientSignin;
