//import "./index.js"
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { forgotPasswordClientUserAPI } from "../services/authService";
import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import EditIcon from '@mui/icons-material/Edit';

import IconButton from '@mui/material/IconButton';

const SuccessMessage = (props) => {
  let copyEmail = { ...props }
  return (
    <Stack spacing={2} sx={{ width: '100%', marginTop: '20px' }}>
      <MuiAlert severity="success" variant="filled">Reset Password Link Sent to <u> {copyEmail.email} </u> </MuiAlert>
    </Stack>
  );
}
function ClientForgotPassword() {

  const { search } = useLocation();
  const redirect_url = new URLSearchParams(search).get("redirect_url");
  const existedEmail = new URLSearchParams(search).get("email");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verificationLinkSent, setVerificationLinkSent] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState([])
  const [emailValidationErrors, setEmailValidationErrors] = useState("")
  const [passwordValidationErrors, setPasswordValidationErrors] = useState("")




  useEffect(() => {
  }, [
  ]);


  let handleSubmit = async (e) => {


    e.preventDefault();
    try {

      setShowLoading(true)

      setErrorMessage("")
      setPasswordValidationErrors("")
      setEmailValidationErrors("")


      let res = await forgotPasswordClientUserAPI(email, redirect_url)

      setShowLoading(false)

      let responseData = await res.json();

      if (res.status === 200 || res.status === 201) {
        // navigate('www.kodefast.com/signin')

        setVerificationLinkSent(true)

      } else if (res.status === 401) {
        setErrorMessage(responseData.message);
        setVerificationLinkSent(false)

      }
      else if (res.status == 422) {
        setValidationErrors(responseData.errors)

        let emailError = responseData.errors.find((error) => error.property == "email")
        if (emailError) {
          let allErrors = emailError.constraints
          let contaivalues = Object.values(allErrors)
          setEmailValidationErrors(contaivalues[contaivalues.length - 1])
        }
        else {
          setEmailValidationErrors("")
        }

        let passwordError = responseData.errors.find((error) => error.property == "password")
        if (passwordError) {
          let allErrors = passwordError.constraints
          let contaivalues = Object.values(allErrors)
          setPasswordValidationErrors(contaivalues[contaivalues.length - 1])
        }
        else {
          setPasswordValidationErrors("")
        }
        setVerificationLinkSent(false)
      }
    } catch (err) {
      setVerificationLinkSent(false)
      console.log(err);
    }
  };

  return (
    <div className="bg-blue-400 h-screen grid my-auto">
      <div className="m-auto w-1/3">
        <div className="py-auto pl-10 rounded-md bg-[white] ">
          <h1 className="text-4xl pt-5 mb-5 ">Forgot Password</h1>
          <form className="" onSubmit={handleSubmit}>
            <div>
              <label className="label">
                <span className="label-text">Email Id</span>
              </label>
              <input type="text"
                placeholder="Email Id"
                className="input input-bordered input-success w-full max-w-sm"
                value={email}
                readOnly={verificationLinkSent}
                onChange={(e) => setEmail(e.target.value)}
              />

              {verificationLinkSent ? (<IconButton onClick={(e) => setVerificationLinkSent(false)}>
                <EditIcon ></EditIcon>
              </IconButton>) : ""}

              <p className="text-[#ff3333] mb-2 space-x-4"> {emailValidationErrors}</p>
            </div>


            <p className="text-[#ff3333] mb-2 space-x-4"> {errorMessage}</p>

            {(showLoading == true ? <p> Requesting Reset password   ...  </p> : "")}
            <div className="pb-5 pt-2">
              <button type="submit" className="btn btn-primary" disabled={showLoading == true ? true : false}>Submit</button>


              <span className="pl-10"><Link className="link link-primary" style={{ "marginLeft": "45%" }} to={"/signin/client?redirect_url=" + redirect_url}  > Signin</Link></span>
            </div>
          </form>


        </div>

        {verificationLinkSent ? <SuccessMessage email={email}></SuccessMessage> : ""}

      </div>
    </div>
  );
}

export default ClientForgotPassword;
