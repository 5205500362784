import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { signupClientUserAPI } from "../services/authService";
import { Link } from "react-router-dom";
import PasswordField from "./../components/PasswordField"

function SignUp() {

  const { search } = useLocation();
  const redirect_url = new URLSearchParams(search).get("redirect_url");
  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/
  const navigate = useNavigate();
  const [userType, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);

  const [validationErrors, setValidationErrors] = useState([])
  const [emailValidationErrors, setEmailValidationErrors] = useState("")
  const [passwordValidationErrors, setPasswordValidationErrors] = useState("")
  const [confirmPasswordValidationErrors, setConfirmPasswordValidationErrors] = useState("")

  useEffect(() => {
  }, []);

  let handleSubmit = async (e) => {

    e.preventDefault();
    try {

      setShowLoading(true)
      setErrorMessage("")
      setPasswordValidationErrors("")
      setEmailValidationErrors("")
      let res = await signupClientUserAPI(email, password, confirm_password, redirect_url)

      setShowLoading(false)

      let responseData = await res.json();

      if (res.status === 200 || res.status === 201) {

        localStorage.setItem('signin-details', JSON.stringify(responseData));

        navigate('/signin/client?redirect_url=' + redirect_url);

      }
      else if (res.status === 400) {
        setErrorMessage(responseData.message);
      }
      else if (res.status === 401) {
        setErrorMessage(responseData.message);
      }
      else if (res.status == 422) {
        setValidationErrors(responseData.errors)

        let emailError = responseData.errors.find((error) => error.property == "email")
        if (emailError) {
          let allErrors = emailError.constraints
          let contaivalues = Object.values(allErrors)
          setEmailValidationErrors(contaivalues[contaivalues.length - 1])
        }
        else {
          setEmailValidationErrors("")
        }

        let passwordError = responseData.errors.find((error) => error.property == "password")
        if (passwordError) {
          let allErrors = passwordError.constraints
          let contaivalues = Object.values(allErrors)

          setPasswordValidationErrors(contaivalues[contaivalues.length - 1])
        }
        else {
          setPasswordValidationErrors("")
        }

        let confirmPasswordError = responseData.errors.find((error) => error.property == "confirm_password")
        if (confirmPasswordError) {
          let allErrors = confirmPasswordError.constraints
          let contaivalues = Object.values(allErrors)
          setConfirmPasswordValidationErrors(contaivalues[contaivalues.length - 1])
        }
        else {
          setConfirmPasswordValidationErrors("")
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  let handlePasswordChange = async (e) => {
    setPassword(e.target.value)
    if (e.target.value && e.target.value.length && !passwordPattern.test(e.target.value)) {
      setPasswordValidationErrors("Must have one Uppercase(A-Z) letter,one lowercase(a-z) letter ,one number(0-9) and one symbol")
    }
    else {
      setPasswordValidationErrors("")
    }
  }

  let handleConfirmPasswordChange = async (e) => {
    setConfirmPassword(e.target.value)
    if (e.target.value && e.target.value.length && !passwordPattern.test(e.target.value)) {
      setConfirmPasswordValidationErrors("Must have one Uppercase(A-Z) letter,one lowercase(a-z) letter ,one number(0-9) and one symbol")
    }
    else {
      setConfirmPasswordValidationErrors("")
    }
  }

  return (
    <div className="bg-blue-400 h-screen grid my-auto">
      <div className="m-auto w-1/3">
        <div className="py-auto pl-10 rounded-md bg-[white] ">
          <h1 className="text-4xl pt-5 mb-5 ">Sign Up</h1>
          <form className="" onSubmit={handleSubmit}>

            <div>
              <label className="label">
                <span className="label-text">Email Id</span>
              </label>
              <input type="text"
                placeholder="Email Id"
                className="input input-bordered input-success w-full max-w-sm"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <p className="text-[#ff3333] mb-2 space-x-4"> {emailValidationErrors}</p>
            </div>

            <div className="mb-5">
              <label className="label">
                <span className="label-text">Password</span>
              </label>


              <PasswordField onChange={(e) => handlePasswordChange(e)} label="Password" value={password}></PasswordField>
              <p className="text-[#ff3333] mb-2 space-x-4"> {passwordValidationErrors}</p>
            </div>
            <div className="mb-5">
              <label className="label">
                <span className="label-text">Confirm Password</span>
              </label>
              <PasswordField onChange={(e) => handleConfirmPasswordChange(e)} label="Confirm Password" value={password}></PasswordField>
              <p className="text-[#ff3333] mb-2 space-x-4"> {confirmPasswordValidationErrors}</p>
            </div>

            <p className="text-[#ff3333] mb-2 space-x-4"> {errorMessage}</p>

            {(showLoading == true ? <p> Signin Up ...  </p> : "")}
            <div className="pb-5">
              <button type="submit" className="btn btn-primary" disabled={showLoading == true ? true : false}>Submit</button>
              <span className="pl-10">Already Have an Account <Link className="link link-primary" to={"/signin/client?redirect_url=" + redirect_url}>Login Here</Link></span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
