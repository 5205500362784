import React, { useState, useEffect } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';



function PasswordField(props) {
    let [showPassword, setShowPassword] = useState(false)
    let [value, setValue] = useState("")

    useEffect(() => {

    }, []);

    return (
        <div>
            <input
                type={showPassword ? "text" : "password"}
                placeholder={props.label}
                className="input input-bordered input-success w-full max-w-sm"
                value={value}
                onChange={(e) => { props.onChange(e); setValue(e.target.value) }}
            />
            {showPassword ? <IconButton onClick={(e) => setShowPassword(false)}>
                <VisibilityIcon ></VisibilityIcon>
            </IconButton> : <IconButton onClick={(e) => setShowPassword(true)}>
                <VisibilityOffIcon ></VisibilityOffIcon>
            </IconButton>}
        </div>
    );
}

export default PasswordField;
