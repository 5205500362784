import React, { useState, useEffect, useRef } from "react";
import { render } from "react-dom";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Link } from "react-router-dom";

const Clientslist = () => {
  const navigate = useNavigate();

  const [name, setName] = useState("");

  const gridRef = useRef();
  const [rowData, setRowData] = useState();
  const [columnDefs, setColumnDefs] = useState([
    { field: "id", headerName: "Id", valueGetter: "node.rowIndex+1", width: "100" },
    { field: "name", headerName: "Name" },
    { field: "domain", headerName: "Domain" },
    { field: "redirect_url", headerName: "Redirect Url" },
    { field: "email", headerName: "Email" },
    {
      headerName: "Action",
      width: "250px",
      cellRenderer: (rowData) => (
        <div className="pt-1">
          <Link to={`/clientsView/${rowData.data.id}`}>
            <button
              className="btn btn-active btn-primary btn-sm "
              style={{ marginRight: "5px", width: "45px" }}
            >
              <svg
                class="w-8 h-8"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                ></path>
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                ></path>
              </svg>
            </button>
          </Link>
          <Link to={`/clientsEdit/${rowData.data.id}`}>
            <button
              className="btn btn-success btn-sm text-white"
              style={{ marginRight: "5px", width: "45px" }}
            >
              <svg
                class="w-8 h-8"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                ></path>
              </svg>
            </button>
          </Link>
          <button
            className="btn btn-warning btn-sm text-white"
            onClick={() => handleDelete(rowData.data)}
            style={{ width: "45px" }}
          >
            <svg
              class="w-8 h-8"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              ></path>
            </svg>
          </button>
        </div>
      ),
    },
  ]);

  const defaultColDef = {
    sortable: true,
    //floatingFilter:true,
    // filter:true
  };

  useEffect(() => {
    let isAuth = JSON.parse(localStorage.getItem("access_token"));
    if (!isAuth) {
      navigate("/");
    }

    getData();
  }, []);

  const logout = () => {
    localStorage.removeItem("access_token")
    navigate("/");
  }

  // get all department data

  const getData = async () => {
    fetch("https://authfast-production.up.railway.app/v1.0/clients", {
      method: "get",
      headers: new Headers({
        "Content-Type": "application/json",
        authorization:
          "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQGF1dGhmYXN0LmNvbSIsImlkIjo1LCJ1c2VyVHlwZSI6InN1cGVyYWRtaW4iLCJpYXQiOjE2NjM2NTE2NzV9.raQf8JTVyiM51zg6OgH1RLkQqK_QyGPO2rJa9-oYDko",
      }),
    })
      .then((result) => result.json())
      .then((rowData) => {
        console.log(rowData.data);
        setRowData(rowData.data);
      });
  };

  // delete row single data

  const handleDelete = (data) => {
    fetch(
      `https://authfast-production.up.railway.app/v1.0/clients/${data.id}`,
      {
        method: "delete",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization:
            "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQGF1dGhmYXN0LmNvbSIsImlkIjo1LCJ1c2VyVHlwZSI6InN1cGVyYWRtaW4iLCJpYXQiOjE2NjM2NTE2NzV9.raQf8JTVyiM51zg6OgH1RLkQqK_QyGPO2rJa9-oYDko",
        }),
        body: JSON.stringify({
          name: name,
        }),
      }
    )
      .then((result) => result.json())
      .then((data) => {
        console.log(data);
        getData();
      });
  };

  return (
    <div>
      <div className="navbar bg-primary text-primary-content">
        <div className="w-full">
          <a className="btn btn-ghost normal-case text-xl">Clients</a>
        </div>
        <div>
          <button className="text-white normal-case text-xl" onClick={logout}>
            Logout
          </button>
        </div>
      </div>

      <div className="w-full mt-3 overflow-hidden">
        <Link to="/clientsAdd">
          <button
            className="btn btn-active btn-primary btn-sm float-right text-white"
            style={{ width: "45px" }}
          >
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              ></path>
            </svg>
          </button>
        </Link>
      </div>

      <div>
        <div
          className="ag-theme-alpine overflow-hidden"
          style={{ height: 500, marginTop: 10 }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            pagination={true}
            paginationPageSize={10}
          //paginationAutoPageSize={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Clientslist;
