import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loginUserAPI } from "../services/authService";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PasswordField from "./../components/PasswordField"
function Signin() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [emailValidationErrors, setEmailValidationErrors] = useState("");
  const [passwordValidationErrors, setPasswordValidationErrors] = useState("");

  useEffect(() => {
    let isAuth = JSON.parse(localStorage.getItem("access_token"));
    if (isAuth && isAuth !== null) {
      navigate("/clients");
    }
  }, []);

  let handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setShowLoading(true);

      setErrorMessage("");
      setPasswordValidationErrors("");
      setEmailValidationErrors("");

      const res = await loginUserAPI(email, password)

      setShowLoading(false);

      let responseData = await res.json();

      if (res.status === 200 || res.status === 201) {
        localStorage.setItem("access_token", JSON.stringify(responseData.access_token));
        navigate("/clients");
      } else if (res.status === 401) {
        setErrorMessage(responseData.message);
      } else if (res.status == 422) {
        setValidationErrors(responseData.errors);

        let emailError = responseData.errors.find(
          (error) => error.property == "email"
        );
        if (emailError) {
          let allErrors = emailError.constraints;
          let contaivalues = Object.values(allErrors);
          setEmailValidationErrors(contaivalues[contaivalues.length - 1]);
        } else {
          setEmailValidationErrors("");
        }

        let passwordError = responseData.errors.find(
          (error) => error.property == "password"
        );
        if (passwordError) {
          let allErrors = passwordError.constraints;
          let contaivalues = Object.values(allErrors);
          setPasswordValidationErrors(contaivalues[contaivalues.length - 1]);
        } else {
          setPasswordValidationErrors("");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="bg-blue-400 h-screen grid my-auto">
      <div className="m-auto w-1/3">
        <div className="py-auto pl-10 rounded-md bg-[white] ">
          <h1 className="text-4xl pt-5 mb-5 ">Sign In</h1>
          <form className="" onSubmit={handleSubmit}>
            <div>
              <label className="label">
                <span className="label-text">Email Id</span>
              </label>
              <input
                type="text"
                placeholder="Email Id"
                className="input input-bordered input-success w-full max-w-sm"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />

              <p className="text-[#ff3333] mb-2 space-x-4">
                {" "}
                {emailValidationErrors}
              </p>
            </div>
            <div className="mb-2">
              <label className="label">
                <span className="label-text">Password</span>
              </label>

              <PasswordField onChange={(e) => setPassword(e.target.value)} label="Password" value={password}></PasswordField>
              <p className="text-[#ff3333] mb-2 space-x-4">
                {" "}
                {passwordValidationErrors}
              </p>
            </div>

            <p className="text-[#ff3333] mb-2 space-x-4"> {errorMessage}</p>

            {showLoading == true ? <p> Signing in ... </p> : ""}
            <div className="pb-5 pt-2">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={showLoading == true ? true : false}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Signin;
