import "./App.css";
import { Routes, Route } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Clientslist from "./components/Clientslist";
import BaseLayout from "./layouts/BaseLayout";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<BaseLayout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="clientslist" element={<Clientslist />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
