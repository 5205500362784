import React, { useState, useEffect, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { fetchUserTokensAPI } from "../services/authService"

const RefreshTokens = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");

  const gridRef = useRef();
  const [rowData, setRowData] = useState();
  const [columnDefs, setColumnDefs] = useState([
    { field: "id", headerName: "Id", valueGetter: "node.rowIndex+1", width: "50" },
    { field: "accessToken", headerName: "AccessToken", width: "400" },
    { field: "refreshToken", headerName: "Refresh", width: "400" },
    { field: "expiresAt", headerName: "ExpiresAt" },
    { field: "source", headerName: "Source" },
  ]);

  const defaultColDef = {
    sortable: true,
    //floatingFilter:true,
    // filter:true
  };

  useEffect(() => {
    let isAuth = JSON.parse(localStorage.getItem("access_token"));
    if (!isAuth) {
      navigate("/");
    }

    getData();
  }, []);

  const logout = () => {
    localStorage.removeItem("access_token")
    navigate("/");
  }
  // get all department data

  const getData = async () => {
    let result = await fetchUserTokensAPI('refresh_token')
    result = await result.json()
    setRowData(result.data);
  };

  return (
    <div>
      <div className="navbar bg-primary text-primary-content">
        <div className="w-full">
          <a className="btn btn-ghost normal-case text-xl">Refresh Tokens</a>
        </div>
        <div>
          <button className="text-white normal-case text-xl" onClick={logout}>
            Logout
          </button>
        </div>
      </div>


      <div>
        <div
          className="ag-theme-alpine overflow-hidden"
          style={{ height: 500, marginTop: 10 }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            pagination={true}
            paginationPageSize={10}
          //paginationAutoPageSize={true}
          />
        </div>
      </div>
    </div>
  );
};

export default RefreshTokens;
