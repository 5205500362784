import React, { useState, useEffect, useRef } from "react";
import { render } from "react-dom";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { fetchUsersAPI } from "../services/authService"
const UsersList = () => {
  const [name, setName] = useState("");
  const navigate = useNavigate();

  const gridRef = useRef();
  const [rowData, setRowData] = useState();
  const [columnDefs, setColumnDefs] = useState([
    { field: "id", headerName: "Id", valueGetter: "node.rowIndex+1", width: "100" },
    { field: "email", headerName: "Email" },
    { field: "client.domain", headerName: "Client" },
  ]);

  const defaultColDef = {
    sortable: true,
    //floatingFilter:true,
    // filter:true
  };

  useEffect(() => {
    let isAuth = JSON.parse(localStorage.getItem("access_token"));
    if (!isAuth) {
      navigate("/");
    }

    getData();
  }, []);

  const logout = () => {
    localStorage.removeItem("access_token")
    navigate("/");
  }
  // get all department data

  const getData = async () => {
    let result = await fetchUsersAPI()
    result = await result.json()
    setRowData(result.data);
  };

  return (
    <div>
      <div className="navbar bg-primary text-primary-content">
        <div className="w-full">
          <a className="btn btn-ghost normal-case text-xl">Users</a>
        </div>
        <div>
          <button className="text-white normal-case text-xl" onClick={logout}>
            Logout
          </button>
        </div>
      </div>

      {/* <div className="w-full mt-3 overflow-hidden">
        <Link to="/clientsAdd">
          <button
            className="btn btn-active btn-primary btn-sm float-right text-white"
            style={{ width: "45px" }}
          >
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              ></path>
            </svg>
          </button>
        </Link>
      </div> */}

      <div>
        <div
          className="ag-theme-alpine overflow-hidden"
          style={{ height: 500, marginTop: 10 }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            pagination={true}
            paginationPageSize={10}
          />
        </div>
      </div>
    </div>
  );
};

export default UsersList;
