import { Link, Outlet } from "react-router-dom";

function BaseLayout() {
  return (
    <div>
      <div className="flex">
        <div className="w-1/5">
          <div className="bg-blue-400 h-screen grid my-auto">
            <div className="menu w-80 p-10">
              <ul>
                {/* <li>
              <Link className="text-white" to="/dashboard">
                Dashboard
              </Link>
              </li> */}
                <li>
                  <Link className="text-white" to="/clients">
                    Clients
                  </Link>
                </li>
                <li>
                  <Link className="text-white" to="/users">
                    Users
                  </Link>
                </li>
                <li>
                  <Link className="text-white" to="/accesstokens">
                    Access Tokens
                  </Link>
                </li>
                <li>
                  <Link className="text-white" to="/refreshtokens">
                    Refresh Tokens
                  </Link>
                </li>
                {/* <li>
              <Link className="text-white" to="/">Logout</Link>
              </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="w-5/6 p-2 bg-white-400">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default BaseLayout;
