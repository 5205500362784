import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { resetPasswordAPI, resetPasswordLinkVerify } from "../services/authService";
import { Link } from "react-router-dom";
import PasswordField from "../components/PasswordField"

import MuiAlert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';


function ClientResetPassword() {

  const { search } = useLocation();
  const redirect_url = new URLSearchParams(search).get("redirect_url");
  const token = new URLSearchParams(search).get("token");

  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/
  const navigate = useNavigate();
  const [invalidLink, setInvalidLink] = useState(false);
  const [verifiedLink, setVerifiedLink] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);


  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(false);

  const [validationErrors, setValidationErrors] = useState([])
  const [emailValidationErrors, setEmailValidationErrors] = useState("")
  const [passwordValidationErrors, setPasswordValidationErrors] = useState("")
  const [confirmPasswordValidationErrors, setConfirmPasswordValidationErrors] = useState("")

  useEffect(() => {
    checkToken()
  }, []);

  const checkToken = async () => {
    setVerifyLoading(true)

    if (!redirect_url || !token) {
      setInvalidLink(true)
      setVerifyLoading(false)
      return
    }

    await checkValidToken()
    setVerifyLoading(false)
  }

  const checkValidToken = async () => {
    try {
      await resetPasswordLinkVerify(token)
      setVerifiedLink(true)
    }
    catch (err) {
      setVerifiedLink(false)
    }
  }




  const Message = (props) => {
    return (
      <Stack spacing={2} sx={{ width: '100%', marginTop: '20px' }}>
        <MuiAlert severity={props.type} variant="filled">{props.children} </MuiAlert>
      </Stack>
    );
  }



  let handleSubmit = async (e) => {

    e.preventDefault();
    try {

      setShowLoading(true)
      setErrorMessage("")
      setPasswordValidationErrors("")
      setEmailValidationErrors("")
      let res = await resetPasswordAPI(token, password, confirm_password)

      setShowLoading(false)

      let responseData = await res.json();

      if (res.status === 200 || res.status === 201) {
        setResetPasswordSuccess(true)
      }
      else if (res.status === 400) {
        setErrorMessage(responseData.message);
        setResetPasswordSuccess(false)

      }
      else if (res.status === 401) {
        setErrorMessage(responseData.message);
        setResetPasswordSuccess(false)

      }
      else if (res.status == 422) {
        setValidationErrors(responseData.errors)

        let emailError = responseData.errors.find((error) => error.property == "email")
        if (emailError) {
          let allErrors = emailError.constraints
          let contaivalues = Object.values(allErrors)
          setEmailValidationErrors(contaivalues[contaivalues.length - 1])
        }
        else {
          setEmailValidationErrors("")
        }

        let passwordError = responseData.errors.find((error) => error.property == "password")
        if (passwordError) {
          let allErrors = passwordError.constraints
          let contaivalues = Object.values(allErrors)

          setPasswordValidationErrors(contaivalues[contaivalues.length - 1])
        }
        else {
          setPasswordValidationErrors("")
        }

        let confirmPasswordError = responseData.errors.find((error) => error.property == "confirm_password")
        if (confirmPasswordError) {
          let allErrors = confirmPasswordError.constraints
          let contaivalues = Object.values(allErrors)
          setConfirmPasswordValidationErrors(contaivalues[contaivalues.length - 1])
        }
        else {
          setConfirmPasswordValidationErrors("")
        }
        setResetPasswordSuccess(false)

      }
    } catch (err) {
      setShowLoading(false)
      setResetPasswordSuccess(false)
      console.log(err);
    }
  };

  let handlePasswordChange = async (e) => {
    setPassword(e.target.value)
    if (e.target.value && e.target.value.length && !passwordPattern.test(e.target.value)) {
      setPasswordValidationErrors("Must have one Uppercase(A-Z) letter,one lowercase(a-z) letter ,one number(0-9) and one symbol")
    }
    else {
      setPasswordValidationErrors("")
    }
  }

  let handleConfirmPasswordChange = async (e) => {
    setConfirmPassword(e.target.value)
    if (e.target.value && e.target.value.length && !passwordPattern.test(e.target.value)) {
      setConfirmPasswordValidationErrors("Must have one Uppercase(A-Z) letter,one lowercase(a-z) letter ,one number(0-9) and one symbol")
    }
    else {
      setConfirmPasswordValidationErrors("")
    }
  }




  return (


    <div className="bg-blue-400 h-screen grid my-auto">

      {
        verifyLoading ? 'loading.....' :
          (<div className="m-auto w-1/3">
            {
              (invalidLink && !verifyLoading) ||
                (!verifiedLink && !verifyLoading)
                ? (<Message type="error">
                  <p> Invalid Link </p>
                </Message>) :

                (<div className="py-auto pl-10 rounded-md bg-[white] ">
                  <h1 className="text-4xl pt-5 mb-5 ">Reset Password</h1>
                  {!resetPasswordSuccess ? (<form className="" onSubmit={handleSubmit}>
                    <div className="mb-5">
                      <label className="label">
                        <span className="label-text">Password</span>
                      </label>


                      <PasswordField onChange={(e) => handlePasswordChange(e)} label="Password" value={password}></PasswordField>
                      <p className="text-[#ff3333] mb-2 space-x-4"> {passwordValidationErrors}</p>
                    </div>
                    <div className="mb-5">
                      <label className="label">
                        <span className="label-text">Confirm Password</span>
                      </label>
                      <PasswordField onChange={(e) => handleConfirmPasswordChange(e)} label="Confirm Password" value={password}></PasswordField>
                      <p className="text-[#ff3333] mb-2 space-x-4"> {confirmPasswordValidationErrors}</p>
                    </div>

                    <p className="text-[#ff3333] mb-2 space-x-4"> {errorMessage}</p>

                    {(showLoading == true ? <p> Updating password ...  </p> : "")}
                    <div className="pb-5">
                      <button type="submit" className="btn btn-primary" disabled={showLoading == true ? true : false}>Submit</button>
                    </div>
                  </form>) : (
                    <div>
                      <Message type="success">
                        <span> Password Reset Successfully
                          <Link className="link link-primary" to={"/signin/client?redirect_url=" + redirect_url}  > Go To Signin</Link>
                        </span>

                      </Message>


                    </div>
                  )
                  }
                </div>
                )
            }
          </div>)


      }

    </div>
  )
};

export default ClientResetPassword;
