import React from 'react'

function Dashboard() {
  return (
  <div className="navbar bg-primary">
    <div className="navbar bg-primary text-primary-content">
        <a className="btn btn-ghost normal-case text-xl">Client Dashboard</a>
     </div>
  </div>
  )
}

export default Dashboard