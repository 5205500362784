//import "./index.js"
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
function ClientSignin() {

  const { search } = useLocation();
  const redirect_url = new URLSearchParams(search).get("redirect_url");

  const checkToken = () => {

    let code = localStorage.getItem('authfast')
    if (code) {
      let seperator = redirect_url.indexOf('?') > -1 ? '&code=' : '?code='
      window.location.href = redirect_url + seperator + code
      return;
    }
    else {
      window.location.href = redirect_url
    }
  }

  useEffect(() => {
    checkToken()
  }, [
  ]);



  return (
    <div className="bg-blue-400 h-screen grid my-auto">
      <div className="m-auto w-1/3">
        <h1 className="text-4xl pt-5 mb-5 ">Logout In</h1>
      </div>
    </div>
  );
}

export default ClientSignin;
