import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";

const ClientsView = () => {
  let params = useParams();
  const [rowData, setRowData] = useState({
    id: "",
    name: ""
  });

  // get single patient data 
  useEffect(() => {
    fetch('https://authfast-production.up.railway.app/v1.0/clients/' + params.data,
      {
        method: "get",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQGF1dGhmYXN0LmNvbSIsImlkIjo1LCJ1c2VyVHlwZSI6InN1cGVyYWRtaW4iLCJpYXQiOjE2NjM2NTE2NzV9.raQf8JTVyiM51zg6OgH1RLkQqK_QyGPO2rJa9-oYDko"
        }),
      })
      .then(result => result.json())
      .then(rowData => {
        console.log(rowData.data)
        console.log(rowData.data.name)
        setRowData(rowData.data)
      })
  }, []);


  return (
    <div>
      <div className="navbar bg-primary text-primary-content">
        <div className='w-full'>
          <a className="btn btn-ghost normal-case text-xl">View Client Details</a>
        </div>
      </div>

      <div class="flex pt-10">
        <div class="w-2/5"></div>
        <div className="w-3/5">
          <div className="card w-96 bg-primary text-neutral-content">
            <div className="card-body items-center text-center">
              <h3 className="card-title">Name : {rowData.name}</h3>
              <h3 className="card-title">Domain : {rowData.domain}</h3>
              <h3 className="card-title">Redirect URL : {rowData.redirect_url}</h3>
              <h3 className="card-title">Email : {rowData.email}</h3>
              <div className="card-actions justify-end">

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <p>Id : {rowData.id}</p>
          <p>Name : {rowData.name}</p> */}
    </div>
  )
}

export default ClientsView