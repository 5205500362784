import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Signin from "./Auth/Signin.js";
import ClientSignin from "./Auth/ClientSignin.js";
import ClientLogout from "./Auth/ClientLogout";
import ClientToken from "./Auth/ClientToken";
import ClientResetPassword from "./Auth/ClientResetPassword";
import ClientForgotPassword from "./Auth/ClientForgotPassword";


import SignUp from "./Auth/SignUp.js";
import Clientslist from "./components/Clientslist.js";
import Dashboard from "./components/Dashboard.js";
import BaseLayout from "./layouts/BaseLayout";
import ClientsView from "./components/ClientsView";
import ClientsAdd from "./components/ClientsAdd";
import ClientsEdit from "./components/ClientsEdit";
import UsersList from "./components/UsersList";
import AccessTokens from "./components/AccessTokens.js";
import RefreshTokens from "./components/RefreshTokens.js";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Signin />} />
      <Route path="/signin/client" element={<ClientSignin />} />
      <Route path="/token/client" element={<ClientToken />} />
      <Route path="/logout/client" element={<ClientLogout />} />
      <Route path="/reset-password/client" element={<ClientResetPassword />} />
      <Route path="/forgot-password/client" element={<ClientForgotPassword />} />

      <Route path="/signup/client" element={<SignUp />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/" element={<BaseLayout />}>
        <Route path="/clients" element={<Clientslist />} />
        <Route path="/users" element={<UsersList />} />
        <Route path="/accesstokens" element={<AccessTokens />} />
        <Route path="/refreshtokens" element={<RefreshTokens />} />
        <Route path="/clientsView/:data" element={<ClientsView />} />
        <Route path="/clientsAdd" element={<ClientsAdd />} />
        <Route path="/clientsEdit/:data" element={<ClientsEdit />} />

      </Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
