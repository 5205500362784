import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ClientsAdd = () => {

  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [domain, setDomain] = useState("");
  const [redirect_url, setRedirectUrl] = useState("");
  const [email, setEmail] = useState("");

  const [nameErr, setNameErr] = useState("");
  const [domainErr, setDomainErr] = useState("");
  const [redirect_urlErr, setRedirectUrlErr] = useState("");
  const [emailValidationErrors, setEmailValidationErrors] = useState("")
  const [errorMessage, setErrorMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);


  const [message, setMessage] = useState("");

  useEffect(() => {
  }, []);

  let handleSubmit = async (e) => {
    console.log("onclick submit buton")
    e.preventDefault();
    try {
      setShowLoading(true)

      let res = await fetch("https://authfast-production.up.railway.app/v1.0/clients", {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json",
          authorization: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFkbWluQGF1dGhmYXN0LmNvbSIsImlkIjo1LCJ1c2VyVHlwZSI6InN1cGVyYWRtaW4iLCJpYXQiOjE2NjM2NTE2NzV9.raQf8JTVyiM51zg6OgH1RLkQqK_QyGPO2rJa9-oYDko"
        }),
        body: JSON.stringify({
          name: name,
          domain: domain,
          redirect_url: redirect_url,
          email: email,

        }),
      });

      setShowLoading(false)

      let responseData = await res.json();
      if (res.status === 200 || res.status === 201) {
        navigate('/clients');
        //  setName("");
        console.log(responseData);
      }
      else if (res.status === 400) {
        setErrorMessage(responseData.message);
      }
      else if (res.status == 422) {
        //setValidationErrors(responseData.errors)

        let nameError = responseData.errors.find((error) => error.property == "name")
        if (nameError) {
          let allErrors = nameError.constraints
          let contaivalues = Object.values(allErrors)
          setNameErr(contaivalues[0])
        }
        else {
          setNameErr("")
        }

        let domainError = responseData.errors.find((error) => error.property == "domain")
        if (domainError) {
          let allErrors = domainError.constraints
          let contaivalues = Object.values(allErrors)
          setDomainErr(contaivalues[0])
        }
        else {
          setDomainErr("")
        }

        let redirecturlError = responseData.errors.find((error) => error.property == "redirect_url")
        if (redirecturlError) {
          let allErrors = redirecturlError.constraints
          let contaivalues = Object.values(allErrors)
          setRedirectUrlErr(contaivalues[0])
        }
        else {
          setRedirectUrlErr("")
        }

        let emailError = responseData.errors.find((error) => error.property == "email")
        if (emailError) {
          let allErrors = emailError.constraints
          let contaivalues = Object.values(allErrors)
          setEmailValidationErrors(contaivalues[0])
        }
        else {
          setEmailValidationErrors("")
        }

      }
    } catch (err) {
      console.log(err);
    }
  };


  return (
    <div>
      <div className="navbar bg-primary text-primary-content">
        <div className='w-full'>
          <a className="btn btn-ghost normal-case text-xl">Create New Client</a>
        </div>
      </div>
      <div class="flex pt-10">
        <div class="w-2/5"></div>

        <div className="w-3/5">
          <form className="" onSubmit={handleSubmit}>
            <div>
              <label className="label">
                <span className="label-text">Name</span>
              </label>
              <input type="text"
                placeholder="Name"
                className="input input-bordered input-success w-full max-w-sm"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <p className="text-[#ff3333] mb-2 space-x-4"> {nameErr}</p>
            </div>
            <div>
              <label className="label">
                <span className="label-text">Domain Name</span>
              </label>
              <input type="text"
                placeholder="Domain Name"
                className="input input-bordered input-success w-full max-w-sm"
                value={domain}
                onChange={(e) => setDomain(e.target.value)}
              />
              <p className="text-[#ff3333] mb-2 space-x-4"> {domainErr}</p>
            </div>
            <div>
              <label className="label">
                <span className="label-text">Redirect URl</span>
              </label>
              <input type="text"
                placeholder="Redirect URL"
                className="input input-bordered input-success w-full max-w-sm"
                value={redirect_url}
                onChange={(e) => setRedirectUrl(e.target.value)}
              />
              <p className="text-[#ff3333] mb-2 space-x-4"> {redirect_urlErr}</p>
            </div>
            <div className="mb-5">
              <label className="label">
                <span className="label-text">Email</span>
              </label>
              <input type="text"
                placeholder="Email"
                className="input input-bordered input-success w-full max-w-sm"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <p className="text-[#ff3333] mb-2 space-x-4"> {emailValidationErrors}</p>
              <p className="text-[#ff3333] mb-2 space-x-4">{errorMessage}</p>
            </div>
            {(showLoading == true ? <p> Creating Client ...  </p> : "")}
            <div className="pb-5">
              <button type="submit" className="btn btn-primary" disabled={showLoading == true ? true : false}>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ClientsAdd