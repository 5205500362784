

export const loginUserAPI = async (email, password) => {
    try {

        const url = `${process.env.REACT_APP_API_URL}/auth/signin`

        const options = {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                Accepts: "application/json",
            }),
            body: JSON.stringify({
                email: email,
                password: password,
            }),
        }
        return await fetch(
            url, options
        );
    }
    catch (err) {
        throw err
    }
}


export const loginClientUserAPI = async (email, password, redirect_url) => {
    try {

        const url = `${process.env.REACT_APP_API_URL}/auth/signin/client`

        const options = {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accepts": "application/json",
            }),
            body: JSON.stringify({
                email: email,
                password: password,
                redirect_url: redirect_url
            }),
        }
        return await fetch(
            url, options
        );
    }
    catch (err) {
        throw err
    }
}

export const signupClientUserAPI = async (email, password, confirm_password, redirect_url) => {
    try {

        const url = `${process.env.REACT_APP_API_URL}/auth/signup/client`

        const options = {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                Accepts: "application/json",
            }),
            body: JSON.stringify({
                email,
                password,
                confirm_password,
                redirect_url
            }),
        }
        return await fetch(
            url, options
        );
    }
    catch (err) {
        throw err
    }
}

export const getAccessToken = () => {
    const accessToken = JSON.parse(localStorage.getItem("access_token"));

    return accessToken || null
}


export const fetchUserTokens = async (type) => {
    try {

        const url = `${process.env.REACT_APP_API_URL}/users/tokens/` + type

        const options = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json",
                Accepts: "application/json",
                authorization: getAccessToken(),
            }),
        }

        return await fetch(
            url,
            options
        )
    }
    catch (err) {
        throw err
    }
}



export const forgotPasswordClientUserAPI = async (email, redirect_url) => {
    try {

        const url = `${process.env.REACT_APP_API_URL}/auth/forgot-password/client`

        const options = {
            method: "POST",
            headers: new Headers({
                "Content-Type": "application/json",
                "Accepts": "application/json",
            }),
            body: JSON.stringify({
                email: email,
                redirect_url: redirect_url
            }),
        }
        return await fetch(
            url, options
        );
    }
    catch (err) {
        throw err
    }
}


export const resetPasswordLinkVerify = async (token) => {
    try {

        const url = `${process.env.REACT_APP_API_URL}/auth/forgot-password/client/verify-link`

        const options = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json",
                Accepts: "application/json"
            }),
            body: JSON.stringify({
                token
            })
        }

        const response = await fetch(
            url,
            options
        )
        if (response.status == 200) {
            return true;
        }
        else {
            throw new Error("Link verified")
        }
    }
    catch (err) {
        throw err
    }
}


export const resetPasswordAPI = async (token, password, confirm_password) => {
    try {

        const url = `${process.env.REACT_APP_API_URL}/auth/reset-password/client`

        const options = {
            method: "post",
            headers: new Headers({
                "Content-Type": "application/json",
                Accepts: "application/json",
                authorization: token,
            }),
            body: JSON.stringify({
                password,
                confirm_password
            })
        }

        return await fetch(
            url,
            options
        )
    }
    catch (err) {
        throw err
    }
}


export const fetchUsersAPI = async () => {
    try {

        const url = `${process.env.REACT_APP_API_URL}/users`

        const options = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json",
                Accepts: "application/json",
                authorization: getAccessToken(),
            })
        }

        return await fetch(
            url,
            options
        )
    }
    catch (err) {
        throw err
    }
}


export const fetchUserTokensAPI = async (type) => {
    try {

        const url = `${process.env.REACT_APP_API_URL}/users/tokens/` + type

        const options = {
            method: "get",
            headers: new Headers({
                "Content-Type": "application/json",
                Accepts: "application/json",
                authorization: getAccessToken(),
            })
        }

        return await fetch(
            url,
            options
        )
    }
    catch (err) {
        throw err
    }
}